<template>
  <div id="reason" class="service-section">
    <div class="wrap" :class="{ 'wrap-visible': contentVisible }">
      <h2>
        あずま造園土木が選ばれる理由
        <hr>
      </h2>
      <div class="reason-list">
        <div class="reason">
          <img class="reason-number" src="@/assets/top/azuma_top_num001.webp" alt="あずま造園土木が選ばれる理由1つ目">
          <div class="reason-content full">
            <h3>お値打ち価格</h3>
            <p>
              小規模な法人のため、間接コストがかからず、低価格でのサービスの提供が可能です。アルミ工事においては大半が自社施工のためコストカットを実現しています。長年の取引実績から安く仕入れができる独自のルートも確保しています。お値打ち価格には自信がありますので、ぜひ一度ご相談ください。
            </p>
          </div>
        </div>
        <div class="reason left">
          <img class="reason-number" src="@/assets/top/azuma_top_num002.webp" alt="あずま造園土木が選ばれる理由2つ目">
          <div class="reason-content half">
            <h3>代表者自身が業務を担当</h3>
            <p>
              創業当初から10年を超えて、代表者が自ら営業・設計・見積り・現場監督・現場作業とすべての業務をおこなっています。代表者自身が長年培った経験と、代表者自身が全業務をおこなうことで、実現できる提案力と技術があずま造園土木の強みです。一つひとつのお仕事を丁寧に仕上げさせていただきます。
            </p>
          </div>
        </div>
        <div class="reason">
          <img class="reason-number" src="@/assets/top/azuma_top_num003.webp" alt="あずま造園土木が選ばれる理由3つ目">
          <div class="reason-content half">
            <h3>お客様のご要望にお応えします</h3>
            <p>
              コミュニケーションを大切にし、お客様のご要望にお応えします。これまで、お客様のどんな無理難題にも、臨機応変に応えてきました。まずは思いをぶつけてください。ご要望をしっかりとくみとり、それを実現させていただきます。
            </p>
          </div>
        </div>
        <div class="reason left">
          <img class="reason-number" src="@/assets/top/azuma_top_num004.webp" alt="あずま造園土木が選ばれる理由4つ目">
          <div class="reason-content half">
            <h3>迅速な対応と無駄のない作業工程</h3>
            <p>
              小規模な組織なので、フットワークの軽い対応ができます。各工事の段取りを大事にし、できるだけ無駄のない作業工程で工事を実施していきます。時間の面でお客様にストレスを与えないことを大切にしています。
            </p>
          </div>
        </div>
      </div>
      <img class="hari008" src="@/assets/top/azuma_top_ira_hari008.webp" alt="山菜を乗せたハリネズミ">
    </div>
    <!-- スライドイン背景 -->
    <div :class="{ 'slide-in': animate }" class="cover-slider"></div>
  </div>
</template>

<script>
export default {
  name: "ReasonVue",
  data() {
    return {
      animate: false,
      contentVisible: false,
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    triggerAnimation() {
      this.animate = true; // アニメーション開始
      const windowWidth = window.innerWidth;
      console.log(windowWidth);
      const setTime = windowWidth <= 767 ? 500 : 1000;
      console.log(setTime);
      setTimeout(() => {
        this.contentVisible = true; // アニメーション完了後にコンテンツを表示
      }, setTime); // アニメーション時間+遅延時間でコンテンツ表示のタイミングを制御
    },
    setupIntersectionObserver() {
      const serviceElement = document.getElementById('reason');
      if (serviceElement) {
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.triggerAnimation();
              this.observer.unobserve(entry.target);
            } else {
              // no action
            }
          });
        }, {
          root: null,
          threshold: 0.1
        });

        this.observer.observe(serviceElement);
      } else {
        // no action
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.service-section {
  position: relative;
  padding-top: 100px;
  .wrap {
    height: 920px;
    max-width: 950px;
    position: relative;
    /* コンテンツ アニメーション表示 */
    transition: transform 1.3s ease-out, opacity 1.3s ease-out;
    transform: scale(0.95);
    opacity: 0;
    @media screen and (max-width: 1100px) {
      height: 1120px;
    }
    @media screen and (max-width: 767px) {
      height: 1870px;
    }
    h2 {
      z-index: 3;
      hr {
        z-index: 3;
      }
    }
    .reason-list {
      z-index: 3;
      .reason {
        display: flex;
        justify-content: start;
        margin: 20px 0;
        @media screen and (max-width: 767px) {
          display: block;
        }
        .reason-number {
          width: 130px;
          height: 130px;
          @media screen and (max-width: 767px) {
            margin: auto;
          }
        }
        .reason-content {
          width: auto;
          h3 {
            font-size: 25px;
            font-weight: 400;
            color: var(--main);
            margin: 10px 0;
            @media screen and (max-width: 767px) {
              font-size: 22px;
              text-align: center;
            }
          }
        }
        .half {
          width: 50%;
          @media screen and (max-width: 767px) {
            width: auto;
          }
        }
      }
      .left {
        justify-content: end;
      }
    }
    .hari008 {
      position: absolute;
      bottom: 110px;
      left: -200px;
      width: 200px;
      height: 160px;
      @media screen and (min-width: 1101px) and (max-width: 1350px) {
        display: none;
      }
      @media screen and (max-width: 767px) {
        position: inherit;
        bottom: 0;
        left: 0;
        width: 150px;
        height: 120px;
        margin: auto;
      }
    }
  }
  .wrap-visible {
    /* コンテンツ アニメーション表示 */
    transform: scale(1);
    opacity: 1;
  }
  .cover-slider {
    position: absolute;
    top: 0;
    background-color: var(--sub2);
    width: 87%;
    height: 1020px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    transform: translateX(-200%);
    transition: transform 1s ease-out;
    z-index: -1;
    @media screen and (min-width: 1101px) and (max-width: 1350px) {
      width: 100%;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
      height: 1220px;
      border-radius: 0;
    }
    @media screen and (max-width: 767px) {
      height: 1920px;
      transition: transform 0.5s ease-out;
    }
  }

































































  .slide-in {
    transform: translateX(0);
    z-index: -1;
    @media screen and (max-width: 1000px) {
      transform: translateX(0);
    }
  }
}
</style>
