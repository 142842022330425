<template>
  <Header />
  <router-view />
  <img
    src="@/assets/top/azuma_modoru_001.webp"
    alt="TOPへ戻る"
    class="top-scroll"
    v-if="showScrollButton"
    v-scroll-to="'#app'"
  >
  <Footer />
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showScrollButton: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showScrollButton = scrollTop > 200;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

/*---------------------
 共通初期設定
---------------------*/
:root {
  --main: #387717;
  --sub1: #ec8045;
  --sub2: #fff1cf;
  --font: #231815;
  --black: #000000;
  --white: #ffffff;
  --gray: #bcbcbc;
}

#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  color: var(--font);
  line-height: 1.5em;
  // overflow-x: hidden;
  @media screen and (max-width: 767px) {
    line-height: 1.6em;
    margin-top: 0;
  }
}

html * {
  box-sizing: border-box !important;
}

body {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

a {
  text-decoration: none;
  color: var(--font);
}

/*---------------------
 display 設定
---------------------*/
.pc-only {
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
.pc-tablet-only {
  display: none;
  @media screen and (min-width: 768px) {
    display: initial !important;
  }
}
.tablet-only {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    display: initial !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}
/*---------------------
 Heading, Paragraph
---------------------*/
h1 {
  color: var(--main);
  font-size: 30px;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 21px;
  }
}
h2 {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  color: var(--font);
  margin: 0;
  @media screen and (max-width: 1100px) {
    font-size: 21px;
  }
  hr {
    width: 45px;
    margin: 30px auto 0 auto;
    border-color: var(--main);
    @media screen and (max-width: 1100px) {
      margin: 10px auto 0 auto;
    }
  }
}
h3 {
  font-size: 25px;
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 19px;
  }
}
h4 {
  text-align: center;
}
p {
  font-size: 16px;
  line-height: 2rem;
  margin: 0;
}
/*---------------------
 Section
---------------------*/
section {
  padding: 200px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 15vh 0;
  }
}
.wrap {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  h2 {
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    color: var(--font);
    margin: 0;
    @media screen and (max-width: 1100px) {
      font-size: 21px;
    }
    hr {
      width: 45px;
      margin: 30px auto 0 auto;
      border-color: var(--main);
      @media screen and (max-width: 1100px) {
        margin: 10px auto 0 auto;
      }
    }
  }
  .content {
    padding: 60px 0;
    @media screen and (max-width: 1100px) {
      padding: 30px 20px;
      width: 100%;
      margin: 0;
    }
  }
}
/*---------------------
 TOPへ戻るスクロールボタン
---------------------*/
.top-scroll {
  width: 70px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  opacity: 0.9;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 50px;
    right: 0;
    bottom: 60px;
  }
}
/*---------------------
 各ページの画像セクション
---------------------*/
.page-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px auto;
  @media screen and (max-width: 767px) {
    display: block;
  }
  .sample-img {
    width: 30%;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
