<template>
  <div class="top">
    <div class="top-video">
      <video ref="player" autoplay muted playsinline loop>
        <source src="/video/shutterstock_1091708393.mp4" />
        このブラウザではサポートされていません。
      </video>
      <img :class="{ 'slide-in': animate }" class="top-title-img pc-tablet-only" src="@/assets/top/azuma_top_title.webp" alt="あずま造園土木">
      <img :class="{ 'slide-in': animate }" class="top-title-img sp-only" src="@/assets/top/azuma_top_title_sp.webp" alt="あずま造園土木">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopVue',
  data() {
    return {
      animate: false
    };
  },
  mounted() {
    this.triggerAnimation();
  },
  methods: {
    triggerAnimation() {
      // ページロード時にアニメーションを起動
      setTimeout(() => {
        this.animate = true;
      }, 500);  // 少し遅延を入れて自然に見せる
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  .top-video {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      height: 90vh;
    }
    video {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 92vh;
      object-fit: cover;
      z-index: 0;
      @media screen and (max-width: 767px) {
        height: 100%;
      }
    }
    .top-title-img {
      position: absolute;
      bottom: 0;
      width: 60%;
      transform: translateX(-200%);
      transition: transform 1s ease-out;
      z-index: 1;
      @media screen and (max-width: 1100px) {
        width: 75%;
      }
    }
    .slide-in {
      transform: translateX(0);
    }
  }
}
</style>
