<template>
  <div id="service" class="service-section">
    <div class="wrap" :class="{ 'wrap-visible': contentVisible }">
      <h2>
        サービス
        <hr>
      </h2>
      <div id="service-contents" class="service-list">
        <div class="construction-outside">
          <img src="@/assets/top/azuma_top_003.webp" alt="外構工事のイメージ">
          <h3>外構工事</h3>
          <p>
            住宅や建物の外側の環境を整える外構工事をお値打ち価格でおこなっています。新築外構、外構リフォームの両方をおこなっています。
          </p>
          <img class="hari003" src="@/assets/top/azuma_top_ira_hari003.webp" alt="右上を向くハリネズミ">
        </div>
        <div class="exterior">
          <img src="@/assets/top/azuma_top_004.webp" alt="エクステリアのイメージ">
          <h3>エクステリア</h3>
          <p>
            日々の生活を豊かにするエクステリアの工事をお値打ち価格でおこなっています。ウッドデッキ、テラス、フェンス、ガーデンルーム、カーポートなどが対応可能です。
          </p>
          <img class="hari004" src="@/assets/top/azuma_top_ira_hari004.webp" alt="お花のイラスト2">
        </div>
      </div>
    </div>
    <!-- スライドイン背景 -->
    <div :class="{ 'slide-in': animate }" class="cover-slider"></div>
  </div>
</template>

<script>
export default {
  name: "ServiceVue",
  data() {
    return {
      animate: false,
      contentVisible: false,
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    triggerAnimation() {
      this.animate = true; // アニメーション開始
      const windowWidth = window.innerWidth;
      const setTime = windowWidth <= 767 ? 500 : 1000;
      setTimeout(() => {
        this.contentVisible = true; // アニメーション完了後にコンテンツを表示
      }, setTime); // アニメーション時間+遅延時間でコンテンツ表示のタイミングを制御
    },
    setupIntersectionObserver() {
      const serviceElement = document.getElementById('service');
      if (serviceElement) {
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.triggerAnimation();
              this.observer.unobserve(entry.target);
            } else {
              // no action
            }
          });
        }, {
          root: null,
          threshold: 0.1
        });

        this.observer.observe(serviceElement);
      } else {
        // no action
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.service-section {
  overflow-x: hidden;
  position: relative;
  padding-top: 100px;
  .wrap {
    left: 20%;
    height: 780px;
    max-width: 950px;
    /* コンテンツ アニメーション表示 */
    transition: transform 1.3s ease-out, opacity 1.3s ease-out;
    transform: scale(0.95);
    opacity: 0;
    @media screen and (max-width: 767px) {
      height: 1050px;
    }
    h2 {
      z-index: 3;
      hr {
        z-index: 3;
      }
    }
    .service-list {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      z-index: 2;
      @media screen and (max-width: 767px) {
        display: block;
        margin-top: 30px;
      }
      .exterior,
      .construction-outside {
        width: 48%;
        position: relative;
        z-index: 2;
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 20px;
        }
        img {
          margin-bottom: 30px;
          z-index: 2;
          @media screen and (max-width: 767px) {
            margin-bottom: 15px;
          }
        }
        h3 {
          font-size: 25px;
          font-weight: 400;
          color: var(--white);
          text-align: center;
          margin-bottom: 15px;
          z-index: 2;
          @media screen and (max-width: 767px) {
            font-size: 19px;
            margin-bottom: 10px;
          }
        }
        .hari003 {
          position: absolute;
          left: 30px;
          top: -100px;
          width: 150px;
          height: 100px;
          z-index: 2;
          @media screen and (max-width: 767px) {
            width: 95px;
            height: 65px;
            left: 0;
            top: -65px;
          }
        }
        .hari004 {
          position: absolute;
          right: -40px;
          top: -80px;
          width: 150px;
          z-index: 2;
          @media screen and (max-width: 767px) {
            width: 100px;
            top: auto;
            right: 0;
            bottom: -120px;
          }
        }
      }
    }
  }
  .wrap-visible {
    /* コンテンツ アニメーション表示 */
    transform: scale(1);
    opacity: 1;
  }
  .cover-slider {
    position: absolute;
    top: 0;
    background-color: var(--sub1);
    width: 90%;
    height: 880px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    transform: translateX(200%);
    transition: transform 1s ease-out;
    z-index: -1;
    overflow-x: hidden;
    @media screen and (min-width: 1101px) and (max-width: 1350px) {
      width: 105%;
      height: 880px;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
      height: 780px;
      border-radius: 0;
    }
    @media screen and (max-width: 767px) {
      height: 1150px;
      transition: transform 0.5s ease-out;
    }
  }
  .slide-in {
    transform: translateX(13%);
    z-index: -1;
    @media screen and (min-width: 1101px) and (max-width: 1350px) {
      transform: translateX(0);
    }
    @media screen and (max-width: 1100px) {
      transform: translateX(0);
    }
  }
}
</style>
