<template>
  <div class="footer">
    <div
      v-if="showFooterScrollButton"
      v-show="this.$route.path === '/'"
      class="sp-contact"
    >
      <div class="scroll-button" v-scroll-to="'#contact'">
        お見積もり（無料）・お問い合わせ
      </div>
    </div>
    <div id="footer" class="footer-content">
      <div class="footer-item">
        <img
          src="@/assets/top/azuma_logo_001.webp"
          alt="株式会社R.C.I."
          class="footer-img"
        />
        <p class="copyright">
          Copyright © azumazouendoboku.com All Rights Reserved.
        </p>
        <img class="footer-point-img"  src="@/assets/top/azuma_top_ira_hari009.webp" alt="フッターのお花">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutFooter",
  data() {
    return {
      showFooterScrollButton: false,
    };
  },
  mounted() {
    // スクロールイベントを監視
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showFooterScrollButton = scrollTop > 200;

      // 特定の要素までスクロールしたかどうかチェック
      const specialElement = document.getElementById("contact"); // 特定の要素のIDを指定する
      if (specialElement) {
        const bounding = specialElement.getBoundingClientRect();
        // 特定の要素の上端が画面内に入った場合、ボタンを非表示
        if (bounding.top >= window.innerHeight) {
          // no action
        } else {
          this.showFooterScrollButton = false;
        }
      } else {
        // no action
      }
    },
  },
};
</script>

<style lang="scss">
.sp-contact {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 60px;
  z-index: 5;
  display: none;
  .scroll-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    padding: 1% 0;
    color: var(--white);
    background-color: var(--black);
    border: 1px solid var(--white);
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
}
.footer {
  height: 200px;
  @media screen and (max-width: 767px) {
    height: 200px;
  }
  .footer-content {
    height: 200px;
    display: flex;
    align-items: end;
    position: relative;
    background-color: var(--sub1);
    @media screen and (max-width: 767px) {
      height: 220px;
    }
    .footer-item {
      width: 100%;
      .footer-img {
        width: 380px;
        margin: 0 auto 20px auto;
        @media screen and (max-width: 767px) {
          width: 280px;
          margin: 0 auto 40px auto;
        }
      }
      .copyright {
        width: 100%;
        text-align: end;
        padding-right: 85px;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          text-align: center;
          font-size: 12px;
          padding: 0;
          margin-bottom: 0;
        }
      }
    }
    .footer-point-img {
      position: absolute;
      bottom: 0;
      width: 100px;
      @media screen and (max-width: 767px) {
        width: 70px;
        top: 10px;
        right: 10px;
        bottom: auto;
      }
    }
  }
}
</style>
