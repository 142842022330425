<template>
  <section>
    <div class="wrap">
      <h2>
        料金
        <hr>
      </h2>
      <div class="wrap-content">
        <p>
          案件によって異なるため、定量的にお伝えできませんが、組織として間接部門がないこと、自社施工によるコストカット、独自ルートでの仕入れなどによって、他社よりもお値打ち価格を実現できる自信があります。見積もりは無料です。まずはお気軽にお問い合わせください。
        </p>
      </div>
      <img class="hari009" src="@/assets/top/azuma_top_ira_hari009.webp" alt="黄色のマリーゴールド">
      <img class="hari010" src="@/assets/top/azuma_top_ira_hari010.webp" alt="風船を持ったハリネズミ">
    </div>
  </section>
</template>

<script>
export default {
  name: "PriceVue",
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    position: relative;
    background: var(--sub1);
    padding-top: 40px;
    padding-bottom: 80px;
    border-radius: 60px;
    @media screen and (max-width: 767px) {
      width: 90%;
      margin: auto;
      padding: 60px 0;
    }
    .wrap-content {
      width: 70%;
      margin: 40px auto 0 auto;
      background-color: var(--white);
      padding: 40px;
      border-radius: 60px;
      @media screen and (max-width: 767px) {
        width: 90%;
        padding: 20px;
        border-radius: 30px;
      }
    }
    .hari009 {
      width: 120px;
      position: absolute;
      top: 0;
      left: -80px;
      @media screen and (min-width: 1101px) and (max-width: 1350px) {
        left: -10px;
      }
      @media screen and (max-width: 1100px) {
        top: -30px;
        left: 0;
      }
      @media screen and (max-width: 767px) {
        width: 100px;
        top: auto;
        bottom: -30px;
        left: -10px;
      }
    }
    .hari010 {
      width: 200px;
      position: absolute;
      right: -180px;
      bottom: 0;
      @media screen and (min-width: 1101px) and (max-width: 1350px) {
        width: 150px;
        right: 0;
        bottom: 0;
      }
      @media screen and (max-width: 1100px) {
        width: 150px;
        right: 0;
        bottom: 0;
      }
      @media screen and (max-width: 767px) {
        width: 120px;
        top: -25px;
        right: 10px;
        bottom: auto;
      }
    }
  }
}
</style>