<template>
  <section>
    <div class="wrap">
      <h2>
        施工事例
        <hr>
      </h2>
      <div class="construction-list">
        <div class="construction">
          <img class="construction-img" src="@/assets/top/azuma_top_s001.webp" alt="新築外構の施工イメージ">
          <h3>新築外構</h3>
          <router-link to="/new-exterior" class="detail-link">詳細を見る</router-link>
        </div>
        <div class="construction construction-space">
          <img class="construction-img" src="@/assets/top/azuma_top_s002.webp" alt="ウッドデッキの施工イメージ">
          <h3>ウッドデッキ</h3>
          <router-link to="/wood-deck" class="detail-link">詳細を見る</router-link>
        </div>
        <div class="construction">
          <div class="point-construction">
            <img class="construction-img" src="@/assets/top/azuma_top_s003.webp" alt="テラスの施工イメージ">
            <img class="hari006" src="@/assets/top/azuma_top_ira_hari006.webp" alt="黄色のチューリップ">
          </div>
          <h3>テラス</h3>
          <router-link to="/terrace" class="detail-link">詳細を見る</router-link>
        </div>
        <div class="construction">
          <div class="point-construction">
            <img class="construction-img" src="@/assets/top/azuma_top_s004.webp" alt="フェンスの施工イメージ">
            <img class="hari005" src="@/assets/top/azuma_top_ira_hari005.webp" alt="黄色のチューリップ">
          </div>
          <h3>フェンス</h3>
          <router-link to="/fence" class="detail-link">詳細を見る</router-link>
        </div>
        <div class="construction construction-space">
          <img class="construction-img" src="@/assets/top/azuma_top_s005.webp" alt="ガーデンルームの施工イメージ">
          <h3>ガーデンルーム</h3>
          <router-link to="/garden-room" class="detail-link">詳細を見る</router-link>
        </div>
        <div class="construction">
          <img class="construction-img" src="@/assets/top/azuma_top_s006.webp" alt="カーポートの施工イメージ">
          <h3>カーポート</h3>
          <router-link to="/carport" class="detail-link">詳細を見る</router-link>
        </div>
        <div class="construction">
          <img class="construction-img" src="@/assets/top/azuma_top_s007.webp" alt="ガレージの施工イメージ">
          <h3>ガレージ</h3>
          <router-link to="/garage" class="detail-link">詳細を見る</router-link>
        </div>
        <div class="construction construction-space">
          <img class="construction-img" src="@/assets/top/azuma_top_s008.webp" alt="外構リフォームの施工イメージ">
          <h3>外構リフォーム</h3>
          <router-link to="/exterior-renovation" class="detail-link">詳細を見る</router-link>
        </div>
        <div class="construction">
          <img class="hari007" src="@/assets/top/azuma_top_ira_hari007.webp" alt="花を見つめるハリネズミたち">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ExampleOfConstruction",
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    .construction-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      .construction {
        width: 30%;
        margin-bottom: 40px;
        position: relative;
        @media screen and (max-width: 767px) {
          width: 90%;
          margin: 0 5% 25px 5%;
        }
        h3 {
          font-size: 25px;
          font-weight: 400;
          text-align: center;
          color: var(--main);
          margin: 20px 0;
          @media screen and (max-width: 767px) {
            font-size: 19px;
            margin: 15px 0;
            font-weight: 500;
          }
        }
        .point-construction {
          position: relative;
          .hari006 {
            width: 25%;
            position: absolute;
            right: -20px;
            bottom: -20px;
            z-index: 1;
          }
          .hari005 {
            width: 25%;
            position: absolute;
            top: -20px;
            left: -30px;
            z-index: 1;
          }
        }
        .construction-img {
          border-radius: 30px;
          @media screen and (max-width: 767px) {
            border-radius: 15px;
          }
        }
        .detail-link {
          display: block;
          width: 100%;
          padding: 15px 0;
          font-size: 18px;
          text-align: center;
          border: 1px solid var(--black);
          @media screen and (max-width: 767px) {
            font-size: 16px;
            padding: 7px 0;
          }
          &:hover {
            color: var(--white);
            background-color: var(--main);
            border-color: var(--main);
          }
        }
        .hari007 {
          position: absolute;
          top: 20%;
          right: 0;
          width: 95%;
          @media screen and (max-width: 767px) {
            position: inherit;
            width: 70%;
            margin: 0 auto;
          }
        }
      }
      .construction-space {
        margin-right: 5%;
        margin-left: 5%;
        @media screen and (max-width: 767px) {
          margin-right: 5%;
          margin-left: 5%;
        }
      }
    }
  }
}
</style>
