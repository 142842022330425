<template>
  <section>
    <div class="wrap page-description">
      <p class="description-text">
        瀬戸市にある株式会社あずま造園土木の{{ pageName }}の施工事例です。あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに、毎年200件の工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料です。
      </p>
      <img src="@/assets/page/sekou_ira001.webp" class="ira001">
    </div>
  </section>
</template>

<script>
export default {
  name: "PageDescription",
  props: {
    pageName: String,
  }
}
</script>

<style lang="scss" scoped>
section {
  padding: 100px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 12vh 0;
  }
  .page-description {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: block;
    }
    .description-text {
      width: 65%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 55%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .ira001 {
      width: 30%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 40%;
      }
      @media screen and (max-width: 767px) {
        width: 80%;
        margin: 30px auto 0 auto;
      }
    }
  }
}
</style>
