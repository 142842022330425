<template>
  <section>
    <div class="wrap">
      <h2>
        よくある質問とその回答
        <hr>
      </h2>
      <div class="faq">
        <div class="question">
          <div class="question-logo">Q</div>
          <h3 class="question-title">対象エリア外ですが、依頼できますか？</h3>
        </div>
        <div class="answer">
          対応可能な場合がございますので、地域と希望の施工内容を、お電話、または、お問い合わせフォームからご連絡ください。
        </div>
      </div>
      <div class="faq">
        <div class="question">
          <div class="question-logo">Q</div>
          <h3 class="question-title">個人宅ではない、法人のお仕事も依頼することはできますか？</h3>
        </div>
        <p class="answer">
          はい、できます。お気軽にお問い合わせください。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FaqVue",
}
</script>

<style lang="scss" scoped>
section {
  padding: 100px 0;
  background-color: var(--sub2);
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 10vh 0;
  }
  @media screen and (max-width: 767px) {
    padding: 12vh 0;
  }
  h2 {
    margin-bottom: 40px;
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  .faq {
    .question {
      display: flex;
      justify-content: space-between;
      background-color: var(--sub1);
      padding:  20px 2.5%;
      @media screen and (max-width: 767px) {
        justify-content: space-around;
        padding:  20px 0;
      }
      .question-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10%;
        font-size: 30px;
        font-weight: 400;
        line-height: 30px;
        color: var(--white);
        padding-bottom: 5px;
        border-right: 1px solid var(--white);
        border-left: 1px solid var(--white);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 25px;
          line-height: 25px;
        }
        @media screen and (max-width: 767px) {
          width: 20%;
          height: 25px;
          font-size: 20px;
          line-height: 20px;
        }
      }
      .question-title {
        display: flex;
        align-items: center;
        width: 87%;
        font-size: 27px;
        font-weight: 400;
        line-height: 30px;
        color: var(--white);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 20px;
          line-height: 25px;
        }
        @media screen and (max-width: 767px) {
          width: 65%;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .answer {
      width: 95%;
      margin: 20px auto 30px auto;
      @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}
</style>