<template>
  <section>
    <div class="wrap">
      <h2>
        あずま造園土木の
        <br class="sp-only">
        より詳しい情報について
      </h2>
      <div class="page-description">
        <p class="description-text">
          ここまであずま造園土木のホームページをご覧いただき、誠にありがとうございます。
          <br>
          <br>
          あずま造園土木は、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市で外構工事・エクステリアをおこなっています。あずま造園土木に興味を持っていただけたようでしたら、あずま造園土木についてさらに詳しい情報をぜひご確認いただきたいです。
          <br>
          <br>
          以下のボタンから、あずま造園土木について、さらに詳しい情報を見ることができますので、ぜひご確認くださいませ。
        </p>
        <div class="ira">
          <img src="@/assets/page/sekou_ira004.webp" class="pc-tablet-only">
          <img src="@/assets/page/sekou_ira005.webp" class="sp-only">
        </div>
      </div>
      <router-link to="/" v-scroll-to="'#app'" class="detail-btn">
        さらに詳しい情報を見てみる
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "ViewMore",
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--main);
  padding: 100px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 12vh 0;
  }
  .wrap {
    h2 {
      color: var(--white);
      text-align: start;
      margin-bottom: 60px;
      @media screen and (max-width: 1100px) {
        text-align: center;
      }
    }
    .page-description {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1100px) {
        display: block;
      }
      .description-text {
        width: 65%;
        color: var(--white);
        @media screen and (max-width: 1100px) {
          width: 100%;
        }
      }
      .ira {
        width: 30%;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 45%;
          margin: 30px auto 0 auto;
        }
        @media screen and (max-width: 767px) {
          width: 80%;
          margin: 30px auto 0 auto;
        }
      }
    }
    .detail-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 60px;
      margin: 40px auto 0 auto;
      background-color: var(--white);
      color: var(--black);
      &:hover {
        background-color: var(--sub1);
      }
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 50%;
        margin: 30px auto 0 auto;
      }
      @media screen and (max-width: 767px) {
        width: 70%;
      }
    }
  }
}
</style>
