<template>
  <section>
    <div class="wrap">
      <div class="promise">
        <h2>
          あずま造園土木から
          <br class="sp-only">
          お客様へのお約束
          <hr>
        </h2>
        <p>
          あずま造園土木は、他社に負けないお値打ち価格での提供に自信があります。お値打ち価格だからといって手を抜くことは一切ありません。小規模組織だからこそできる丁寧な提案や施工も強みです。お客様のご希望をお値打ち価格で実現することをお約束いたします。
        </p>
      </div>
      <img class="hari013" src="@/assets/top/azuma_top_ira_hari013.webp" alt="花を持ったハリネズミ">
    </div>
  </section>
</template>
<script>
export default {
  name: "PromiseVue"
}
</script>
<style lang="scss" scoped>
section {
  padding: 100px 0;
  background-color: var(--sub1);
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 10vh 0;
  }
  @media screen and (max-width: 767px) {
    padding: 5vh 0;
    margin-bottom: 30px;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    padding: 40px 5%;
    border-radius: 80px;
    background-color: var(--white);
    @media screen and (max-width: 1100px) {
      border-radius: 60px;
    }
    @media screen and (max-width: 767px) {
      display: block;
      width: 90%;
      margin: auto;
      position: relative;
    }
    .promise {
      width: 78%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 73%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h2 {
        text-align: start;
        color: var(--main);
        @media screen and (max-width: 767px) {
          text-align: center;
        }
        hr {
          width: 100%;
          border-color: var(--main);
          @media screen and (max-width: 767px) {
            width: 90%;
            margin-right: auto;
            margin-left: auto;
          }
        }
      }
      p {
        margin-top: 20px;
      }
    }
    .hari013 {
      width: 20%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width:  25%;
      }
      @media screen and (max-width: 767px) {
        width:  120px;
        position: absolute;
        right: 10px;
        bottom: -75px;
      }
    }
  }
}
</style>