<template>
  <section>
    <div class="wrap">
      <h2>
        会社紹介
        <hr>
      </h2>
      <div class="profile-list">
        <img class="company-img" src="@/assets/top/azuma_top_kaisya001.webp" alt="会社の建物の写真">
        <div class="profile">
          <div class="company-profile-table">
            <div class="company-profile-row">
              <div class="company-profile-title">社名</div>
              <div class="company-profile">株式会社あずま造園土木</div>
            </div>
            <div class="company-profile-row">
              <div class="company-profile-title">代表者</div>
              <div class="company-profile">東 岳弘</div>
            </div>
            <div class="company-profile-row">
              <div class="company-profile-title">住所</div>
              <div class="company-profile">愛知県瀬戸市品野町1-150</div>
            </div>
            <div class="company-profile-row">
              <div class="company-profile-title">電話番号</div>
              <div class="company-profile">
                0561-84-0571
                <br>
                ※受付：9時〜19時（定休日なし）
              </div>
            </div>
          </div>
          <div class="qualifications">
            <h3>資格</h3>
            <p>1級造園施工管理技士、2級土木施工管理技士、2級建設業経理士、第2種電気工事士、移動式クレーン、高所作業車運転、刈払機伐木特別教育、玉掛技能、愛知県農薬管理指導士</p>
          </div>
        </div>
      </div>
      <img class="hari011 pc-only" src="@/assets/top/azuma_top_ira_hari011.webp" alt="柵から覗くハリネズミ">
      <img class="hari011_sp sp-only" src="@/assets/top/azuma_top_ira_hari011.webp" alt="柵から覗くハリネズミ">
    </div>
  </section>
</template>

<script>
export default {
  name: "CompanyProfile",
}
</script>

<style lang="scss" scoped>
section {
  padding-top: 0;
  .wrap {
    position: relative;
    .profile-list {
      display: flex;
      justify-content: space-between;
      margin: 60px 0;
      @media screen and (max-width: 1100px) {
        display: block;
        margin: 30px 0;
      }
      .company-img {
        width: 55%;
        @media screen and (max-width: 1100px) {
          width: 100%;
          margin: 20px auto;
        }
      }
      .profile {
        position: relative;
        width: 42%;
        @media screen and (max-width: 1100px) {
          width: 100%;
          margin: 20px auto;
        }
        .company-profile-table {
          border-top: 1px solid var(--black);
          .company-profile-row {
            display: flex;
            margin-top: 10px;
            border-bottom: 1px solid var(--black);
            .company-profile-title {
              width: 30%;
              padding-bottom: 10px;
              padding-left: 15px;
              border-right: 1px solid var(--black);
              display: flex;
              align-items: center;
              @media screen and (max-width: 767px) {
                padding-left: 5px;
              }
            }
            .company-profile {
              width: 70%;
              padding-bottom: 10px;
              padding-left: 20px;
              @media screen and (max-width: 767px) {
                padding-left: 10px;
              }
            }
          }
        }
        .qualifications {
          h3 {
            font-size: 18px;
            text-align: center;
            color: var(--white);
            background-color: var(--main);
            padding: 10px 0;
            border-radius: 999px;
            margin-top: 30px;
            margin-bottom: 15px;
          }
        }
      }
    }
    .hari011 {
      position: absolute;
      bottom: 0;
      left: -300px;
      width: 280px;
      @media screen and (max-width: 767px) {
        position: inherit;
        width: 100%;
      }
    }
  }
}
</style>
