<template>
  <section>
    <div class="wrap">
      <h2>
        対応エリア
        <hr>
      </h2>
      <div class="supported-area">
        <img class="supported-area-map" src="@/assets/top/azuma_top_map.webp" alt="あずま造園土木の対応エリア">
        <img class="hari012" src="@/assets/top/azuma_top_ira_hari012.webp" alt="オレンジの花">
      </div>
      <p>
        あずま造園土木は、低コストとフットワークの軽い対応を実現するために、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市を中心に地域密着型でお仕事のご依頼を承っています。
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "SupportedArea"
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--main);
  .wrap {
    h2 {
      color: var(--white);
      hr {
        border-color: var(--white);
      }
    }
    .supported-area {
      position: relative;
      margin: 40px 0;
      @media screen and (max-width: 767px) {
        margin: 30px 0;
      }
      .supported-area-map {
        width: 50%;
        margin: auto;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .hari012 {
        position: absolute;
        top: -120px;
        width: 160px;
        @media screen and (max-width: 767px) {
          top: -90px;
          left: -15px;
          width: 100px;
        }
      }
    }
    p {
      color: var(--white);
      line-height: 1.3rem;
    }
  }
}
</style>
