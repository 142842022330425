<template>
  <section>
    <h2>
      代表者紹介
      <hr>
    </h2>
    <div class="wrap ceo-profile">
      <p class="profile-text sp-only">
        ホームページをご覧いただき、ありがとうございます。 私たちの手掛けるエクステリア・ガーデン工事は、みなさまの毎日の生活にとても身近で、心を豊かにできる工事です。 そんなエクステリア・ガーデン工事だからこそ、心を込めて丁寧にご提案・工事を進めて行きたいと思っております。 大きな会社ではありませんが、だからこそできるきめの細かい提案や施工を心がけています。 丁寧な提案、丁寧な施工を心がけておりますので多くのお見積り、図面、施工には少し時間がかかることがございます。 工事費用に関して大きな会社組織と比べ経費がかかりません。必然的に同じ品質で安く工事を行うことが可能です。泥臭く、職人さんと一緒に汗水たらして1つひとつ丁寧に現場を仕上げていく。そんな毎日が私はとても楽しく感じています。スマートなやり方ではないかもしれませんが、あずま造園土木はそんな個人会社です。 ぜひお気軽にお仕事のご相談をお願いいたします。
      </p>
      <div class="img-area">
        <img src="@/assets/top/azuma_top_daihyo.webp" alt="株式会社あずま造園土木 代表取締役 東 岳弘">
        <p class="company-name">株式会社あずま造園土木</p>
        <h3>代表取締役 東 岳弘</h3>
        <div class="ira pc-tablet-only">
          <img src="@/assets/top/azuma_top_ira_hari008.webp" alt="右を向くハリネズミ" class="hari008">
        </div>
      </div>
      <div class="text-area">
        <p class="profile-text">
          ホームページをご覧いただき、ありがとうございます。 私たちの手掛けるエクステリア・ガーデン工事は、みなさまの毎日の生活にとても身近で、心を豊かにできる工事です。 そんなエクステリア・ガーデン工事だからこそ、心を込めて丁寧にご提案・工事を進めて行きたいと思っております。 大きな会社ではありませんが、だからこそできるきめの細かい提案や施工を心がけています。 丁寧な提案、丁寧な施工を心がけておりますので多くのお見積り、図面、施工には少し時間がかかることがございます。 工事費用に関して大きな会社組織と比べ経費がかかりません。必然的に同じ品質で安く工事を行うことが可能です。泥臭く、職人さんと一緒に汗水たらして1つひとつ丁寧に現場を仕上げていく。そんな毎日が私はとても楽しく感じています。スマートなやり方ではないかもしれませんが、あずま造園土木はそんな個人会社です。 ぜひお気軽にお仕事のご相談をお願いいたします。
        </p>
        <hr class="text-hr">
        <div class="ceo-careers">
          <div class="ceo-career">
            <p class="period">1996年3月</p>
            <p class="career">瀬戸市立古瀬戸小学校 卒業</p>
          </div>
          <div class="ceo-career">
            <p class="period">1999年3月</p>
            <p class="career">瀬戸市立祖東中学 卒業</p>
          </div>
          <div class="ceo-career">
            <p class="period">2002年3月</p>
            <p class="career">愛知県立高蔵寺高校 卒業</p>
          </div>
          <div class="ceo-career">
            <p class="period">2006年3月</p>
            <p class="career">名城大学理工学部建設システム工学科 卒業</p>
          </div>
          <div class="ceo-career">
            <p class="period">2006年4月</p>
            <p class="career">エクステリア＆ガーデンの専門店 入社</p>
          </div>
          <div class="ceo-career">
            <p class="period">2013年5月</p>
            <p class="career">あずま造園土木設立</p>
          </div>
          <img src="@/assets/top/azuma_top_ira_hari002.webp" alt="黄色の花お花" class="hari002 pc-tablet-only">
          <div class="sp-ira">
            <img src="@/assets/top/azuma_top_ira_hari008.webp" alt="右を向くハリネズミ">
            <div class="hari008">
              <img src="@/assets/top/azuma_top_ira_hari002.webp" alt="黄色の花お花">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CeoProfile",
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub1);
  overflow-x: hidden;
  h2 {
    color: var(--white);
    hr {
      color: var(--white);
      border-color: var(--white);
      background-color: var(--white);
    }
  }
  .wrap {
    padding: 40px;
    border-radius: 40px;
    margin-top: 40px;
    background-color: var(--white);
    @media screen and (max-width: 767px) {
      width: 90%;
      padding: 20px;
      border-radius: 20px;
    }
  }
  .ceo-profile {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: block;
    }
    .profile-text {
        font-weight: 400;
        line-height: 1.8rem;
        margin-bottom: 40px;
    }
    .img-area {
      width: 30%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      img {
        @media screen and (max-width: 768px) {
          margin: 20px 0;
        }
      }
      .company-name {
        font-weight: 500;
        @media screen and (min-width: 768px) {
          margin-top: 20px;
        }
      }
      h3 {
        font-weight: 400;
        margin-bottom: 40px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 20px;
        }
        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      .ira {
        display: none;
        @media screen and (min-width: 768px) {
          display: flex;
          justify-content: end;
        }
        .hari008 {
          width: 60%;
        }
      }
    }
    .text-area {
      width: 65%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .profile-text {
        font-weight: 400;
        line-height: 1.8rem;
        display: none;
        @media screen and (min-width: 768px) {
          display: flex;
          justify-content: end;
        }
      }
      .text-hr {
        margin: 40px 0;
        @media screen and (max-width: 768px) {
          margin: 20px 0;
        }
      }
      .ceo-careers {
        position: relative;
        .ceo-career {
          width: 70%;
          display: flex;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 10px;
          }
          p {
            font-weight: 400;
          }
          .period {
            width: 30%;
          }
          .career {
            width: 70%;
            line-height: 1.5rem;
          }
        }
        .hari002 {
          position: absolute;
          top: 0;
          right: 0;
          width: 25%;
        }
      }
      .sp-ira {
        display: none;
        img,
        .hari008 {
          width: 40%;
          display: flex;
          justify-content: end;
          img {
            width: 100%;
          }
        }
        @media screen and (max-width: 767px) {
          display: flex !important;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
