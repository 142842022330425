<template>
  <section>
    <div class="wrap">
      <h2>
        お問い合わせから施工の流れ
        <hr>
      </h2>
      <div class="flow-list">
        <div class="flow-content">
          <div class="flow-number">STEP 1</div>
          <div class="flow">
            <h3>お電話またはメールにて問い合わせ</h3>
            <p>当ホームページからお電話またはメールでまずはお問い合わせをください。</p>
          </div>
        </div>
        <div class="flow-content">
          <div class="flow-number">STEP 2</div>
          <div class="flow">
            <h3>初回打ち合わせの日程調整</h3>
            <p>
              打ち合わせ希望の場合は、お会いしての打ち合わせのための日程調整をさせていただきます。	
            </p>
          </div>
        </div>
        <div class="flow-content">
          <div class="flow-number">STEP 3</div>
          <div class="flow">
            <h3>打ち合わせと現地確認</h3>
            <p>
              依頼内容をお聞きする打ち合わせと、現地確認をして、業務の進め方を決定していきます。
            </p>
          </div>
        </div>
        <div class="flow-content">
          <div class="flow-number">STEP 4</div>
          <div class="flow">
            <h3>見積作成（図面）</h3>
            <p>
              打ち合わせの内容をもとにお見積りを作成いたします。また、図面が必要な工事の場合は、図面を作成いたします（3と4を繰り返し、依頼内容を決定していきます）。
            </p>
          </div>
        </div>
        <div class="flow-content">
          <div class="flow-number">STEP 5</div>
          <div class="flow">
            <h3>ご契約</h3>
            <p>
              ご依頼内容が最終決定しましたら、契約を締結します。
            </p>
          </div>
        </div>
        <div class="flow-content">
          <div class="flow-number">STEP 6</div>
          <div class="flow">
            <h3>着工</h3>
            <p>
              ご依頼いただいた内容の工事に着手します。現場で細かな調整をかけることで、理想の外構・エクステリアを作り上げていきます。
            </p>
          </div>
        </div>
        <div class="flow-content">
          <div class="flow-number">STEP 7</div>
          <div class="flow">
            <h3>工事完了</h3>
            <p>
              工事完了確認後に問題がなければ完成となります。追加のご要望がある場合は、お気軽にご相談ください。
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FlowVue"
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    .flow-list {
      margin-top: 60px;
      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
      .flow-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        @media screen and (max-width: 767px) {
          display: block;
        }
        .flow-number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20%;
          height: 52px;
          font-size: 21px;
          font-weight: 400;
          border-radius: 999px;
          color: var(--white);
          background-color: var(--main);
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        .flow {
          width: 75%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          h3 {
            font-weight: 400;
            line-height: 1rem;
            margin-bottom: 15px;
            color: var(--main);
            @media screen and (max-width: 767px) {
              margin: 15px 0;
              text-align: center;
            }
          }
          p {
            line-height: 1.3rem;
          }
        }
      }
    }
  }
}
</style>
