import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state() {
    return {
      rootUrl: "https://azumazouendoboku.com",
    };
  },
  getters: {
    rootUrl(state) {
      return state.rootUrl;
    },
  },
  mutations: {
    setRootUrl(state, payload) {
      state.rootUrl = payload;
    },
  },
  actions: {
    setRootUrl({ commit }) {
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://azumazouendoboku.com"
          : "http://azumazouendoboku.com.localhost";
      const payload = {
        rootUrl: rootUrl,
      };
      commit("setRootUrl", payload);
    },
  },
  plugins: [createPersistedState()],
});
